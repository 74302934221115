// For checking lenth of array of links
const getHeight = (length: number, showfull: boolean) => {
  if (length > 5) {
    return showfull ? '100%' : '25rem';
  }

  return '100%';
};

export default getHeight;
