import React from 'react';
import { Box, Typography, Grid } from '@mui/material/';
import Markdown from 'markdown-to-jsx';
import { CardExploreFoxtons } from '../../components/CardExploreFoxtons';
import markdownOverrides from '../../utils/MarkdownOverrides';
import styles from './styles';

export interface LibExploreFoxtonsProps {
  title?: string;
  richText?: string;
  cardsContent?: Array<{
    id: string;
    title: string;
    linkList: Array<{
      id: string;
      label: string;
      href: string;
    }>;
  }>;
  cardsPerRow?: number;
}

const overrides = {
  ...markdownOverrides,
  h1: {
    component: Typography,
    props: { variant: 'h1', sx: styles.exploreFoxtonSectionTitle }
  },
  h2: {
    component: Typography,
    props: { variant: 'h2', sx: styles.exploreFoxtonSectionTitle }
  },
  h3: {
    component: Typography,
    props: { variant: 'h3', sx: styles.exploreFoxtonSectionTitle }
  }
};

function LibExploreFoxtons({
  title,
  cardsContent,
  richText,
  cardsPerRow = 3
}: LibExploreFoxtonsProps) {
  return (
    <Box sx={styles.exploreFoxtonSection} component="section">
      {richText && <Markdown options={{ overrides }}>{richText}</Markdown>}
      {title?.length !== 0 && (
        <Typography variant="h2" sx={styles.exploreFoxtonSectionTitle}>
          {title}
        </Typography>
      )}
      <Grid container spacing={{ xs: '1.25rem', md: '0.625rem', lg: '1rem' }}>
        {cardsContent.map((item) => (
          <Grid
            item
            xs={12}
            md={12 / cardsPerRow}
            sx={styles.exploreFoxtonSectionGridItem}
            key={item.id}
          >
            <CardExploreFoxtons title={item.title} linkList={item.linkList} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export const ExploreFoxtons = React.memo(LibExploreFoxtons);

export default ExploreFoxtons;
