import React, { useState } from 'react';
import { Box, Card, Typography } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import markdownOverrides from '../../utils/MarkdownOverrides';
import { Button } from '../Button';
import getHeight from './utility';
import styles from './styles';

const MarkdownLink = markdownOverrides.a.component;

export interface LibCardExploreFoxtonsProps {
  title: string;
  linkList: Array<{
    id: string;
    label: string;
    href: string;
  }>;
}

function LibCardExploreFoxtons({
  title,
  linkList
}: LibCardExploreFoxtonsProps) {
  const [showfull, setShowfull] = useState(false);

  return (
    <Card
      sx={{
        ...styles.baseState,
        height: getHeight(linkList.length, showfull)
      }}
      className="cardBox"
    >
      <Box sx={showfull ? styles.internalBoxVL : styles.internalBoxVM}>
        {linkList.length > 5 ? (
          <Box>
            <Box sx={styles.ViewMoreBtnBox}>
              <Button
                color="primary"
                variant="secondary"
                sx={styles.ViewMoreBtn}
                onClick={() => setShowfull((prevCheck) => !prevCheck)}
              >
                {showfull ? 'View Less' : 'View All'}
                <ExpandLessIcon
                  sx={{
                    transform: showfull ? 'rotate(0deg)' : 'rotate(180deg)'
                  }}
                />
              </Button>
            </Box>
            <Box
              sx={{
                ...styles.whiteFadeBox,
                display: showfull ? 'none' : 'block'
              }}
            />
          </Box>
        ) : null}
        <Typography variant="h3" sx={styles.title}>
          {title}
        </Typography>
        <Box sx={styles.linkBox}>
          {linkList.map(({ id, label, href }) => (
            <MarkdownLink
              key={id}
              id={id}
              label={label}
              href={href}
              title={label}
            >
              <Typography variant="body" component="span">
                {label}
              </Typography>
            </MarkdownLink>
          ))}
        </Box>
      </Box>
    </Card>
  );
}

export const CardExploreFoxtons = React.memo(LibCardExploreFoxtons);
