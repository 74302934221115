import { SxProps } from '@mui/material';
import { lightTheme } from '../../theme/index';

const { palette, spacing } = lightTheme;

const styles: Record<string, SxProps> = {
  baseState: {
    padding: {
      xs: spacing(1),
      md: spacing(2)
    },
    backgroundColor: palette.primary['50'],
    boxShadow: 'none',
    borderRadius: '0',
    width: '100%',
    position: 'relative'
  },
  title: {
    color: palette.grey.main,
    marginBottom: spacing(1)
  },
  linkBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    fontSize: '1rem',
    '& a': {
      color: palette.grey['800'],
      textDecoration: 'none',
      marginBottom: spacing(1),
      '&:last-child': {
        marginBottom: '0'
      }
    }
  },
  internalBoxVM: {
    height: '110%',
    paddingbottom: '0'
  },
  internalBoxVL: {
    height: '100%',
    paddingBottom: '3rem'
  },
  ViewMoreBtnBox: {
    position: 'absolute',
    bottom: '1rem',
    left: '0',
    width: '100%',
    zIndex: '2',
    display: 'flex',
    justifyContent: 'center'
  },
  ViewMoreBtn: {
    borderRadius: '2.375rem',
    backgroundColor: palette.primary['400'],
    color: palette.primary.main,
    borderColor: palette.primary['400'],
    display: 'flex',
    flexDirection: 'column',
    textTransform: 'uppercase',
    '& svg': {
      width: '5rem'
    }
  },
  whiteFadeBox: {
    position: 'absolute',
    bottom: '0',
    left: '0',
    height: '10rem',
    width: '100%',
    background: palette.gradient[600],
    zIndex: '1'
  }
};

export default styles;
