import { SxProps } from '@mui/material';
import { lightTheme } from '../../theme/index';

const { palette, spacing } = lightTheme;
const styles: Record<string, SxProps> = {
  exploreFoxtonSection: {
    backgroundColor: palette.grey['50'],
    padding: {
      xs: `${spacing(2)} ${spacing(1)}`,
      md: `${spacing(2)} ${spacing(4)}`
    }
  },
  exploreFoxtonSectionTitle: {
    color: palette.primary.main,
    marginBottom: spacing(1)
  },
  exploreFoxtonSectionGridItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '0'
  }
};

export default styles;
